@import "/styles/variables";

.list {
  min-width: 200px;
  margin: 0;
  padding: 0;
}

.item {
  list-style: none;
  border-radius: 12px;

  & > * {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-right: 16px;
  }

  & > a > svg {
    margin-right: 11px;
    vertical-align: text-top;
    fill: $color-greyscale-700;
  }

  &:hover {
    background: $color-greyscale-200;
  }

  &.selected:hover {
    background-color: $color-greyscale-200;
  }
}

.iconBar {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding: 12px 11px;

  &:hover {
    background: unset;
  }

  a svg {
    fill: $color-greyscale-700;
  }
}

.divider {
  border: 0;
  border-bottom: solid $color-greyscale-300 1px;
}
