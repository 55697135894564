@import "/styles/variables";
@import "/shared/ui/Text/Text.module";

.dropdown {
  @include media("mobile") {
    max-height: calc(100vh - #{$navbar-height-mobile});
    margin-top: 14px;
  }
  @include media("desktop", "oversized") {
    min-width: 450px;
  }

  position: absolute;
  top: 100%;
  right: 0;
  left: 0;

  overflow: auto;
  display: none;

  width: 100%;
  min-height: 58px;
  max-height: calc(100vh - #{$navbar-height});
  margin-top: 16px;

  background: $color-white;
  border: solid 1px $color-greyscale-400;
  border-radius: 16px;

  &:focus {
    outline: none;
  }
}

.container {
  --background-color: #{$color-greyscale-200};
  --border-color: #{$color-greyscale-300};

  position: relative;

  display: flex;
  gap: 18px;
  align-items: stretch;

  padding: 4px;

  background: var(--background-color);
  border: none;
  border-radius: 12px;

  &:focus {
    svg {
      fill: $color-greyscale-900;
    }
  }

  &:hover:not(:focus) {
    .button {
      svg {
        fill: $color-greyscale-900;
      }
    }
  }

  &.borderOnMobile {
    @include media("mobile", "tablet") {
      outline: solid 3px var(--border-color);
    }
  }

  &.open {
    &.borderOnMobile {
      outline: solid 3px var(--border-color);
    }

    .dropdown {
      display: block;
    }
  }

  .button,
  .input,
  .tooltip {
    background: none;
    border: none;
  }

  .input {
    flex: 1;
    width: 0;
    line-height: unset;
    appearance: none;

    &::placeholder {
      overflow: hidden !important;

      max-width: 100%;

      color: $color-greyscale-700;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
    }

    &,
    &::placeholder {
      @extend %body-l;

      font-family: $font-family-body;
    }
  }

  .button,
  .contentType {
    border: none;

    &:focus {
      outline: none;
    }

    &:focus,
    &:hover {
      background: $color-greyscale-400;
    }
  }

  .button {
    cursor: pointer;
    width: 50px;

    @include media("mobile") {
      width: 40px;
    }

    svg {
      width: 22px;
      height: 22px;
      fill: $color-greyscale-700;
      transition: 0.15s fill ease;
    }
  }

  .tooltip {
    position: relative;
    border-radius: 16px 0 0 16px;

    > [data-tooltip] {
      min-width: 100%;
    }
  }

  .contentType {
    @include media("mobile", "tablet") {
      // Override width restriction from using `hideText` prop
      width: initial;

      // Override button theme - should be grey even before dropdown opens
      background-color: $color-greyscale-200;

      &:hover {
        background-color: $color-greyscale-500;
      }
    }

    padding: 12px 11px 12px 16px;
    border-color: $color-greyMinus1;

    span {
      overflow: unset !important;
      gap: 8px;
      text-overflow: unset !important;
    }

    &:hover svg {
      &:first-of-type {
        transform: rotate(0deg);
      }

      &:last-of-type {
        fill: $color-greyPlus1;
      }
    }

    svg {
      margin: 0;
      fill: $color-greyscale-600;
      transition: 0.15s fill ease;

      &:first-of-type {
        transform: rotate(0deg);
      }

      &:last-of-type {
        fill: $color-greyscale-700;
      }
    }
  }

  @include media("mobile", "tablet") {
    // On mobile+tablet, search bar takes full width of header and doesn't have the grey background
    --background-color: #{$color-white};
    --divider-color: transparent;
  }

  @include media("desktop", "oversized") {
    &.open {
      --background-color: #{$color-white};
      --border-color: #{$color-greyscale-300};
    }
  }
}
