@import "/styles/variables";

$headings-letter-spacing: -0.04;
$body-letter-spacing: -0.03;

@mixin font-icon-size($size, $lineHeight, $letter-spacing: 0) {
  font-size: $size;
  line-height: $lineHeight;
  letter-spacing: $letter-spacing;

  svg {
    width: $size * 1.2;
    min-width: $size * 1.2;
    max-width: $size * 1.2;
    height: $size * 1.2;
    min-height: $size * 1.2;
    max-height: $size * 1.2;
  }
}

@mixin weight-medium {
  font-weight: $font-weight-medium;

  strong {
    font-weight: $font-weight-semibold;
  }
}

@mixin weight-semibold {
  font-weight: $font-weight-semibold;

  strong {
    font-weight: $font-weight-bold;
  }
}

@mixin weight-bold {
  font-weight: $font-weight-bold;

  strong {
    font-weight: $font-weight-bold;
  }
}

/**
  * Colors that match up to the UI kit
  */

@mixin color($color, $border-color: $color) {
  color: $color;
  fill: $color;

  .underlined .innerText::after {
    border-color: $border-color;
  }

  &-icon {
    svg {
      fill: $color;
    }
  }
}

@layer utilities {
  // Mobile size variations NEED TO BE AFTER DESKTOP size variations so they always take precedence
  @mixin mobileTextClasses {
    .size-inherit-mobile {
      font-size: inherit;
      font-weight: inherit;
    }

    .size-h50-mobile {
      // headlines-h1-mobile
      @include font-icon-size(40px, 110%, $headings-letter-spacing * 40px);
    }

    .size-h39-mobile {
      // headlines-h2-mobile
      @include font-icon-size(32px, 110%, $headings-letter-spacing * 32px);
    }

    .size-h28-mobile {
      // headlines-h3-mobile
      @include font-icon-size(24px, 120%, $headings-letter-spacing * 24px);
    }

    .size-h23-mobile {
      // headlines-h4-mobile
      @include font-icon-size(20px, 130%, $headings-letter-spacing * 20px);
    }

    .size-h21-mobile {
      // headlines-h4-mobile
      @include font-icon-size(20px, 130%, $headings-letter-spacing * 20px);
    }

    .size-h18-mobile {
      // headlines-h5-mobile
      @include font-icon-size(18px, 130%, $headings-letter-spacing * 18px);
    }

    .size-p18-mobile {
      // body-l
      @include font-icon-size(18px, 150%, $body-letter-spacing * 18px);
    }

    .size-p16-mobile {
      // body-m
      @include font-icon-size(16px, 150%, $body-letter-spacing * 16px);
    }

    .size-p14-mobile {
      // body-s
      @include font-icon-size(14px, 150%, $body-letter-spacing * 14px);
    }
  }

  // Common defaults (heading/body)
  %headlines-h1-desktop,
  %headlines-h2-desktop,
  %headlines-h3-desktop,
  %headlines-h4-desktop,
  %headlines-h5-desktop {
    @include weight-medium;

    font-family: $font-family-heading;
  }

  %label-default,
  %label-small,
  %body-l,
  %body-m,
  %body-s {
    @include weight-medium;

    font-family: $font-family-body;
  }

  // Headings (desktop)
  %headlines-h1-desktop {
    // letter-spacing = -6%
    @include font-icon-size(48px, 110%, $headings-letter-spacing * 48px);

    p:not(:last-child) {
      margin-bottom: $space-50;
    }
  }

  %headlines-h2-desktop {
    @include font-icon-size(36px, 130%, $headings-letter-spacing * 36px);

    p:not(:last-child) {
      margin-bottom: $space-50;
    }
  }

  %headlines-h3-desktop {
    @include font-icon-size(32px, 130%, $headings-letter-spacing * 32px);

    p:not(:last-child) {
      margin-bottom: $space-30;
    }
  }

  %headlines-h4-desktop {
    @include font-icon-size(24px, 130%, $headings-letter-spacing * 24px);

    p:not(:last-child) {
      margin-bottom: $space-20;
    }
  }

  %headlines-h5-desktop {
    @include font-icon-size(20px, 130%, $headings-letter-spacing * 20px);

    p:not(:last-child) {
      margin-bottom: $space-20;
    }
  }

  // Body text (desktop & mobile)
  %body-l {
    @include font-icon-size(17px, 160%, $body-letter-spacing * 18px);

    p:not(:last-child) {
      margin-bottom: $space-20;
    }
  }

  %body-m {
    @include font-icon-size(16px, 160%, $body-letter-spacing * 16px);

    p:not(:last-child) {
      margin-bottom: $space-15;
    }
  }

  %body-s {
    @include font-icon-size(14px, 160%, $body-letter-spacing * 14px);

    p:not(:last-child) {
      margin-bottom: $space-8;
    }
  }

  // Labels (desktop & mobile)
  %label-default {
    @include font-icon-size(17px, 160%, $body-letter-spacing * 17px);
  }

  %label-small {
    @include font-icon-size(16px, 160%, $body-letter-spacing * 16px);
  }

  /**
  * Base classes added to all text elements
  */
  .text {
    display: block;
    background: none;
    border: none;
    outline: none;

    &:focus {
      outline: none;
    }

    a {
      color: inherit;
    }

    strong {
      display: inline;
    }
  }

  .color-greyMinus3F7F7F7 {
    @include color($color-greyMinus3);
  }

  .color-greyMinus2DFDFE0 {
    @include color($color-greyMinus2, $color-greyMinus3);
  }

  .color-greyMinus1BFBFBF {
    @include color($color-greyMinus1, $color-greyMinus2);
  }

  .color-grey7F7F7F {
    @include color($color-grey, $color-greyMinus1);
  }

  .color-grey747474 {
    @include color($color-greyscale-800, $color-grey);
  }

  .color-greyPlus14A4A4A {
    @include color($color-greyPlus1, $color-grey);
  }

  .color-midnight2C343E {
    @include color($color-midnight);
  }

  .color-black000000 {
    @include color($color-black);
  }

  .color-whiteFFFFFF {
    @include color($color-white);
  }

  .color-green05A081 {
    @include color($color-green);
  }

  .color-redD3405C {
    @include color($color-red);
  }

  .color-purple7831ED {
    @include color($color-purple);
  }

  /**
    * Sizes that match up to the UI kit.
    */
  .size-inherit {
    font-size: inherit;
    font-weight: inherit;
  }

  // Forgive me. Until we fix Text for good, I needed this for storybook.
  .text-explicit-heading,
  // Common heading styles
  .size-h60,
  .size-h49,
  .size-h33,
  .size-h23,
  .size-h21,
  .size-h18 {
    @include weight-medium;

    font-family: $font-family-heading;
  }

  // Forgive me. Until we fix Text for good, I needed this for storybook.
  .text-explicit-body,
  // Common body styles
  .size-p14,
  .size-p16,
  .size-p18 {
    @include weight-medium;

    font-family: $font-family-body;
  }

  .size-h60 {
    @extend %headlines-h1-desktop;
  }

  .size-h49 {
    @extend %headlines-h2-desktop;
  }

  .size-h33 {
    @extend %headlines-h3-desktop;
  }

  .size-h23 {
    @extend %headlines-h4-desktop;
  }

  .size-h21 {
    @extend %headlines-h4-desktop;
  }

  .size-h18 {
    @extend %headlines-h5-desktop;
  }

  .size-p18 {
    @extend %body-l;
  }

  .size-p16 {
    @extend %body-m;
  }

  .size-p14 {
    @extend %body-s;
  }

  .size-label-default {
    @extend %label-default;
  }

  .size-label-small {
    @extend %label-small;
  }

  // add this class to parent if you want to render mobile text styles without needing mobile breakpoint
  .textMobileExplicit {
    @include mobileTextClasses;
  }

  /**
    * Font weight overrides
    * NEEDS TO BE AFTER FONT SIZE CLASSES
    * By default the font weight lines up with the font size
    * And these classes override them.
    */
  .weight-inherit {
    font-weight: inherit;
  }

  .weight-bold {
    @include weight-bold;
  }

  .weight-semibold {
    @include weight-semibold;
  }

  .weight-medium {
    @include weight-medium;
  }

  .weight-book {
    font-weight: inherit;

    // To avoid errors, used in the current version, not in the new design
  }

  .weight-black {
    font-weight: inherit;

    // To avoid errors, used in the current version, not in the new design
  }

  /**
    * Options
    */
  .center {
    text-align: center;
  }

  .inline {
    display: inline-block;
  }

  .display-inline {
    display: inline;
  }

  .display-inline-block {
    display: inline-block;
  }

  .display-block {
    display: block;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .disabled {
    cursor: not-allowed;
  }

  .iconRight,
  .icon {
    display: flex;
    align-items: center;

    &.inline {
      display: inline-flex;
    }

    .iconSvg {
      margin-right: 0.4em;
    }

    .iconRightSvg {
      margin-left: 0.4em;
    }
  }

  .iconRotated {
    svg {
      transform: rotate(-180deg);
    }
  }

  .underlined .innerText {
    border-bottom: 2px dotted #c7c7c7;

    &:hover,
    &:focus {
      filter: brightness(0.87);
    }
  }

  .solidUnderlined .innerText {
    border-bottom: 1px solid currentcolor;

    &:hover,
    &:focus {
      filter: brightness(0.87);
    }
  }

  .noWrap {
    white-space: nowrap;
  }

  .noLineHeight:not(.ellipsisWrapper) {
    line-height: 1;
  }

  .ellipsis {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ellipsisWrapper {
    &::before,
    &::after {
      content: "";
      display: block;
    }

    .innerText {
      overflow: hidden;

      /* special case to avoid cutting the bottom parts of the letters */
      display: block;

      max-width: 100%;

      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  // Generate new Tokens class names.
  @include generate-tokencolor-classes;

  @include media("desktop") {
    .size-inherit-desktop {
      font-size: inherit;
      font-weight: inherit;
    }

    .size-h33-desktop {
      @include font-icon-size(33px, 110%, $headings-letter-spacing * 33px);
    }

    .size-h27-desktop {
      @include font-icon-size(27px, 120%, $headings-letter-spacing * 27px);
    }

    .size-h19-desktop {
      @include font-icon-size(19px, 130%, $headings-letter-spacing * 19px);
    }

    .size-p15-desktop {
      @include font-icon-size(15px, 150%, $body-letter-spacing * 15px);
    }

    .size-p14-desktop {
      @include font-icon-size(14px, 150%, $body-letter-spacing * 14px);
    }
  }

  @include media("mobile", "tablet") {
    @include mobileTextClasses;
  }
}
