@import "/styles/variables";

.mobile {
  @include media("tablet", "oversized") {
    display: none !important;
  }
}

.tablet {
  @include media("mobile") {
    display: none !important;
  }

  @include media("desktop", "oversized") {
    display: none !important;
  }
}

.desktop {
  @include media("mobile", "tablet") {
    display: none !important;
  }

  @include media("oversized") {
    display: none !important;
  }
}

.oversized {
  @include media("mobile", "desktop") {
    display: none !important;
  }
}

.mobile-tablet {
  @include media("desktop", "oversized") {
    display: none !important;
  }
}

.mobile-desktop {
  @include media("oversized") {
    display: none !important;
  }
}

.tablet-desktop {
  @include media("mobile") {
    display: none !important;
  }

  @include media("oversized") {
    display: none !important;
  }
}

.tablet-oversized {
  @include media("mobile") {
    display: none !important;
  }
}

.desktop-oversized {
  @include media("mobile", "tablet") {
    display: none !important;
  }
}
