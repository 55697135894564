@import "/styles/variables";

$indicatorSize: 20; // Size of dropdown indicator, within trigger element
$transitionDelay: 150ms, 200ms; // Any :hover effects should have this delay applied to them

.container {
  position: relative;

  display: flex;
  flex-wrap: nowrap; // Ensure arrow indicator stays to the right of trigger, not below
  align-items: center;
  justify-content: center;

  width: fit-content; // Want this container to wrap content as tightly as possible

  // Small chevron icon that indicates if the tooltip is open
  svg[data-indicator] {
    width: $indicatorSize;
    height: $indicatorSize;
    margin-right: -4px;

    // Align the indicator to fit neatly next to content, without overly inflating the containing element's bounds (assumes rounded parent)
    margin-left: 2px;

    fill: $color-greyscale-600;

    transition: 0.25s transform;
  }

  // Tooltip wrapper
  > [data-tooltip] {
    pointer-events: none;

    position: absolute;
    z-index: $z-index-dropdowns;
    transform: translateY(-10px);

    visibility: hidden;
    opacity: 0;

    transition: 0.25s all;

    &[data-alignment="top"],
    &[data-alignment="top-left"],
    &[data-alignment="top-right"] {
      transform: translateY(10px);
    }

    &[data-alignment="left"] {
      transform: translateX(10px);
    }

    &[data-alignment="right"] {
      transform: translateX(-10px);
    }

    // Tooltip Content
    > * {
      padding: 8px;

      background-color: $color-white;
      border: solid $color-greyscale-400 1px;
      border-radius: 16px;
      box-shadow: 0 8px 8px 0 rgb(25 25 25 / 4%);

      &[data-text] {
        width: max-content;
        max-width: 300px;
        padding: 0;
      }
    }
  }

  // Hover overrides
  &:hover:not([data-visible="false"]),
  &[data-visible="true"] {
    svg[data-indicator] {
      transform: rotate(180deg);
    }

    > [data-tooltip] {
      pointer-events: all;
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
    }
  }

  // Some special-case styling for the "fixed" alignment
  &.fixed {
    position: static;

    & > [data-tooltip] {
      right: 0;
      left: 0;
      margin: 0 20px;

      // Want to suppress the padding applied by createStyleOverrides
      padding-top: 0 !important;
    }
  }
}
