@import "/styles/variables";

ul.suggestions {
  margin: 0;
  padding: $space-15 0;
  list-style: none;

  li {
    a {
      display: block;
      width: 100%;
      padding: $space-8 $space-20;

      &:hover {
        background: $color-greyMinus3;
      }

      &:focus {
        background: $color-greyMinus2;
        outline: none;
      }
    }
  }
}
