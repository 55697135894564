// Synced with: components/ui/colors.ts

// Greyscale colors

$color-greyscale-white: #fff;
$color-greyscale-100: #fafafa;
$color-greyscale-200: #f7f7f7;
$color-greyscale-300: #f5f5f5;
$color-greyscale-400: #ededed;
$color-greyscale-500: #e5e5e5;
$color-greyscale-600: #cdcdcd;
$color-greyscale-700: #a8a8a8;
$color-greyscale-800: #747474;
$color-greyscale-900: #393939;
$color-greyscale-shadow: #191919;
$color-greyscale-black: #000;

// Primary colors

$color-alien-dark: #32ac64;
$color-alien-mid: #54ca84;
$color-alien-light: #ddf4e6;
$color-alien-lighter: #bbeace;

$color-jellyfish-dark: #7a7add;
$color-jellyfish-mid: #a2a2fc;
$color-jellyfish-light: #ececfe;
$color-jellyfish-lighter: #dadafe;

$color-papaya-dark: #de5c1c;
$color-papaya-mid: #ff7b3a;
$color-papaya-light: #ffe5d8;
$color-papaya-lighter: #ffc4a7;

$color-strawberry-dark: #db255f;
$color-strawberry-mid: #ff3a78;
$color-strawberry-light: #ffe3ec;
$color-strawberry-lighter: #ffb4cc;

$color-sunshine-dark: #fdca08;
$color-sunshine-mid: #ffdb52;
$color-sunshine-light: #fff8dc;
$color-sunshine-lighter: #fff1ba;

// Colour aliases

$color-white: $color-greyscale-white;
$color-shadow: $color-greyscale-shadow;
$color-black: $color-greyscale-black;
$color-alien: $color-alien-mid;
$color-jellyfish: $color-jellyfish-mid;
$color-papaya: $color-papaya-mid;
$color-strawberry: $color-strawberry-mid;
$color-sunshine: $color-sunshine-mid;

// Legacy colour palette

$color-greyMinus3: $color-greyscale-200;
$color-greyMinus2: #dfdfe0;
$color-greyMinus1: #bfbfbf;
$color-grey: #7f7f7f;
$color-greyPlus1: #4a4a4a;
$color-midnight: $color-greyscale-shadow;
$color-noir: $color-greyscale-shadow;

// $color-black: $color-greyscale-black;
// $color-white: $color-greyscale-white;
$color-green: $color-alien;
$color-red: $color-strawberry;
$color-purple: $color-jellyfish;
$color-yellow: $color-sunshine;

// Define a map linking color names to their SCSS variables
$tokens-color-map-legacy: (
  "white": $color-white,
  "greyMinus3": $color-greyMinus3,
  "greyMinus2": $color-greyMinus2,
  "greyMinus1": $color-greyMinus1,
  "grey": $color-grey,
  "greyPlus1": $color-greyPlus1,
  "midnight": $color-midnight,
  "noir": $color-noir,
  "shadow": $color-shadow,
  "black": $color-black,
  "green": $color-green,
  "red": $color-red,
  "purple": $color-purple,
  "yellow": $color-yellow,
  "alien": $color-alien,
  "jellyfish": $color-jellyfish,
  "papaya": $color-papaya,
  "strawberry": $color-strawberry,
  "sunshine": $color-sunshine,
);

$tokens-color-map: (
  "greyscale-white": $color-greyscale-white,
  "greyscale-100": $color-greyscale-100,
  "greyscale-200": $color-greyscale-200,
  "greyscale-300": $color-greyscale-300,
  "greyscale-400": $color-greyscale-400,
  "greyscale-500": $color-greyscale-500,
  "greyscale-600": $color-greyscale-600,
  "greyscale-700": $color-greyscale-700,
  "greyscale-800": $color-greyscale-800,
  "greyscale-900": $color-greyscale-900,
  "greyscale-shadow": $color-greyscale-shadow,
  "greyscale-black": $color-greyscale-black,
  "alien-dark": $color-alien-dark,
  "alien-mid": $color-alien-mid,
  "alien-light": $color-alien-light,
  "alien-lighter": $color-alien-lighter,
  "jellyfish-dark": $color-jellyfish-dark,
  "jellyfish-mid": $color-jellyfish-mid,
  "jellyfish-light": $color-jellyfish-light,
  "jellyfish-lighter": $color-jellyfish-lighter,
  "papaya-dark": $color-papaya-dark,
  "papaya-mid": $color-papaya-mid,
  "papaya-light": $color-papaya-light,
  "papaya-lighter": $color-papaya-lighter,
  "strawberry-dark": $color-strawberry-dark,
  "strawberry-mid": $color-strawberry-mid,
  "strawberry-light": $color-strawberry-light,
  "strawberry-lighter": $color-strawberry-lighter,
  "sunshine-dark": $color-sunshine-dark,
  "sunshine-mid": $color-sunshine-mid,
  "sunshine-light": $color-sunshine-light,
  "sunshine-lighter": $color-sunshine-lighter,
);
