@import "/styles/variables";

:export {
  /* stylelint-disable property-no-unknown */
  wrapperPaddingDesktop: $wrapper-padding-desktop;
  wrapperPaddingMobile: $wrapper-padding-mobile;
  wrapperPaddingOversized: $wrapper-padding-oversized;
  wrapperPaddingTablet: $wrapper-padding-tablet;
  /* stylelint-enable */
}

@layer layout {
  .black {
    background: $color-black;
  }

  .maxwidth-none {
    display: block;
  }

  .maxwidth-default {
    @include media("tablet", "oversized") {
      margin-right: auto;
      margin-left: auto;
    }

    @include media("tablet") {
      max-width: $wrapper-size-tablet;
    }

    @include media("desktop") {
      max-width: $wrapper-size-desktop;
    }

    @include media("oversized") {
      max-width: $wrapper-size-oversized;
    }

    display: block;
  }

  .maxwidth-slim {
    @include media("tablet", "oversized") {
      margin-right: auto;
      margin-left: auto;
    }

    @include media("desktop", "oversized") {
      max-width: $wrapper-max-width-slim;
    }

    display: block;
  }

  .maxwidth-narrow {
    @include media("tablet", "oversized") {
      margin-right: auto;
      margin-left: auto;
    }

    @include media("tablet") {
      max-width: $wrapper-size-tablet;
    }

    @include media("desktop", "oversized") {
      max-width: $wrapper-max-width-narrow;
    }

    display: block;
  }

  .maxwidth-medium {
    @include media("tablet", "oversized") {
      margin-right: auto;
      margin-left: auto;
    }

    @include media("tablet") {
      max-width: $wrapper-max-width-medium;
    }

    @include media("desktop", "oversized") {
      max-width: $wrapper-max-width-medium;
    }

    display: block;
  }

  .horizontalPadding {
    @include media("mobile") {
      padding-right: $wrapper-padding-mobile;
      padding-left: $wrapper-padding-mobile;
    }

    @include media("tablet") {
      padding-right: $wrapper-padding-tablet;
      padding-left: $wrapper-padding-tablet;
    }

    @include media("desktop") {
      padding-right: $wrapper-padding-desktop;
      padding-left: $wrapper-padding-desktop;
    }

    @include media("oversized") {
      padding-right: $wrapper-padding-oversized;
      padding-left: $wrapper-padding-oversized;
    }
  }

  .hack {
    margin: 0;
    padding: 0;
    border: 0.1px solid rgb(0 0 0 / 0%);
  }

  %edge-margin-extended-tablet-below {
    @include media("mobile") {
      left: -$wrapper-padding-mobile;
      width: calc(100% + #{$wrapper-padding-mobile * 2});
    }

    @include media("tablet") {
      left: -$wrapper-padding-tablet;
      width: calc(100% + #{$wrapper-padding-tablet * 2});
    }
  }

  // when you have wrapper with maxwidth not default, negative margin
  // on desktop, oversized come out of grid. Hence a need for this class.
  .edgeMarginExtendedTabletBelow {
    @extend %edge-margin-extended-tablet-below;
  }

  .edgeMarginExtended {
    @extend %edge-margin-extended-tablet-below;

    @include media("desktop") {
      left: -$wrapper-padding-desktop;
      width: calc(100% + #{$wrapper-padding-desktop * 2});
    }

    @include media("oversized") {
      left: 0;
      width: 100%;
    }
  }
}
