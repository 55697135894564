@import "/styles/variables";

.container {
  min-width: unset !important;
}

.contentType {
  width: 100%;
  height: unset;
  background: unset;

  span {
    justify-content: flex-start;
  }

  svg {
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
  }
}
