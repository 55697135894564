// Synced with: types/font.tsx

$font-family-body:
  "Canva Sans",
  "Helvetica Neue",
  "Roboto",
  -apple-system,
  blinkmacsystemfont,
  sans-serif;

$font-family-label:
  "Canva Sans",
  "Helvetica Neue",
  "Roboto",
  -apple-system,
  blinkmacsystemfont,
  sans-serif;

$font-family-heading:
  "Canva Sans Bold",
  "Helvetica Neue",
  "Roboto",
  -apple-system,
  blinkmacsystemfont,
  sans-serif;

$font-weight-bold: 700;

/**
 * Since the 2024 rebrand, $font-weight-semibold is now an alias for $font-weight-medium, as the new font does not
 * provide a "semibold" weight.
 */
$font-weight-semibold: 500;
$font-weight-medium: 400;
