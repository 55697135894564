@import "media";

// Synced with: components/ui/spaces.ts

$space-4: 4px;
$space-8: 8px;
$space-12: 12px;
$space-15: 15px;
$space-16: 16px;
$space-20: 20px;
$space-24: 24px;
$space-30: 30px;
$space-32: 32px;
$space-50: 50px;
$space-64: 64px;
$space-80: 80px;

// Wrapper paddings
$wrapper-padding-mobile: $space-15;
$wrapper-padding-tablet: $space-20;
$wrapper-padding-desktop: $space-30;
$wrapper-padding-oversized: $space-30;
$wrapper-size-tablet: 900px;
$wrapper-size-desktop: 1440px;
$wrapper-size-oversized: 1600px;
$wrapper-max-width: 1440px;
$wrapper-max-width-narrow: 864px;
$wrapper-max-width-slim: 750px;
$wrapper-max-width-medium: 1080px;

// use this on width:100% on oversized as margin-right to simulate max-width of 1440px
$grid-margin-fixed-oversized: calc(50vw - #{$media-desktop-max / 2});
