@use "sass:list";

// Breakpoints
$media-mobile-max: 650px;
$media-tablet-max: 900px;
$media-desktop-max: 1440px;

// Lookup tables
$media-ordered-sizes: "mobile", "tablet", "desktop", "oversized";

$media-ordered-min:
  0,
  #{$media-mobile-max + 1},
  #{$media-tablet-max + 1},
  #{$media-desktop-max + 1};

$media-ordered-max:
  $media-mobile-max,
  #{$media-tablet-max},
  #{$media-desktop-max},
  null;

@mixin media-errors($min, $max) {
  $min-index: list.index($media-ordered-sizes, $min);
  $max-index: list.index($media-ordered-sizes, $max);

  @if not $min-index {
    @error "$min must be one of: (#{$media-ordered-sizes}) but received (#{$min})";
  }

  @if $max != null and $max-index == null {
    @error "$max must be one of: (#{$media-ordered-sizes}) but received (#{$max})";
  }
}

@function media-build-query-string($min-value, $max-value) {
  @if $max-value {
    @return "(min-width: #{$min-value}) and (max-width: #{$max-value})";
  }

  @return "(min-width: #{$min-value})";
}

@mixin media-build-query($min, $max) {
  $min-index: list.index($media-ordered-sizes, $min);
  $max-index: list.index($media-ordered-sizes, $max);
  $min-value: list.nth($media-ordered-min, $min-index);
  $max-value: false;

  @if not $max {
    $max-value: list.nth($media-ordered-max, $min-index);
  } @else {
    $max-value: list.nth($media-ordered-max, $max-index);
  }

  @media #{media-build-query-string($min-value, $max-value)} {
    @content;
  }
}

/**
 * USAGE:
 * If you only want something to happen on a certain device size then only include one argument of that size:
 * 
 * @include media('desktop') {
 *   // Content here will only happen on the desktop device size
 * }
 *
 * If you want something to happen over a range of breakpoints, but not all of them, then add both args:
 * 
 * @include media('tablet', 'desktop') {
 *   // Content here will start showing up at 'tablet' size and extend till we get larger then the 'desktop' max width
 * }
 */
@mixin media($min, $max: null) {
  @include media-errors($min, $max);

  @include media-build-query($min, $max) {
    @content;
  }
}
