@use "sass:map";
@import "spaces";
@import "colors";

@mixin hidden-scroll {
  scrollbar-width: none; /* Firefox */
  overflow: scroll;

  -ms-overflow-style: none; /* IE and Edge */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin margin-grid-responsive($oversized: true) {
  @include media("mobile") {
    padding-right: $wrapper-padding-mobile;
    padding-left: $wrapper-padding-mobile;
  }

  @include media("tablet") {
    padding-right: $wrapper-padding-tablet;
    padding-left: $wrapper-padding-tablet;
  }

  @include media("desktop") {
    padding-right: $wrapper-padding-desktop;
    padding-left: $wrapper-padding-desktop;
  }

  @if $oversized {
    @include media("oversized") {
      padding-right: $wrapper-padding-oversized;
      padding-left: $wrapper-padding-oversized;
    }
  }
}

// Mixin to generate .color-X classes dynamically
@mixin generate-tokencolor-classes($property: "color") {
  $colors: map.keys($tokens-color-map);
  @each $color in $colors {
    .color-#{$color} {
      #{$property}: map.get($tokens-color-map, $color);
    }
  }
}

@mixin margin-grid-responsive-property($property: "margin-right", $factor: 1) {
  @include media("mobile") {
    #{$property}: $wrapper-padding-mobile * $factor;
  }

  @include media("tablet") {
    #{$property}: $wrapper-padding-tablet * $factor;
  }

  @include media("desktop") {
    #{$property}: $wrapper-padding-desktop * $factor;
  }

  @include media("oversized") {
    #{$property}: $wrapper-padding-oversized * $factor;
  }
}

// mixin to hide something < custom breakpoint
@mixin hide-below($breakpoint) {
  @media screen and (width < $breakpoint) {
    display: none !important;
  }
}

// mixin to hide something >= custom breakpoint
@mixin hide-above($breakpoint) {
  @media screen and (width >= $breakpoint) {
    display: none !important;
  }
}
